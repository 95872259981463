import { useState, useEffect } from 'react';
import { mediaRangeQueries } from 'pws-design-system/design-system/theme';

const useMediaQuery = (query: string, defaultState: boolean = false) => {
    const isBrowser = typeof window === 'object';
    const queryStr = query.replace('@media ', '');
    const [state, setState] = useState(isBrowser ? (
        () => window.matchMedia(queryStr).matches
    ) : defaultState);

    useEffect(() => {
        let mounted = true;
        const mql = window.matchMedia(queryStr);
        const onChange = () => {
            if (!mounted) {
                return;
            }
            setState(!!mql.matches);
        };

        mql.addListener(onChange);
        window.addEventListener('resize', onChange);
        setState(mql.matches);

        return () => {
            mounted = false;
            mql.removeListener(onChange);
            window.removeEventListener('resize', onChange);
        };
    }, [query]);

    return state;
};

export const useMobileQuery = () => {
    const isMobile = useMediaQuery(mediaRangeQueries['tablet-down'], false);
    return isMobile;
};

export default useMediaQuery;
