import { Badge, Box, BoxProps, Popover, Text } from 'pws-design-system/design-system';
import React from 'react';
import { getStatusDescription, getStatusStyle, getStatusText } from '../../models/stations/Station';
import { StatusType } from '../../types/enums';

interface ActivityIndicatorProps extends BoxProps {
    value: StatusType
}

const StatusBadge = ({ value, ...rest }: ActivityIndicatorProps) => {
    const { style, color } = getStatusStyle(value);
    const description = getStatusDescription(value);
    const text = getStatusText(value);
    return (
        <Box position="relative">
            <Popover.Container trigger="hover" placement="right" usePortal>
                <Popover.Trigger>
                    <Badge variant={style} variantColor={color} {...rest}>{text}</Badge>
                </Popover.Trigger>
                <Popover.Content zIndex={4}>
                    <Popover.Arrow bg="white" />
                    <Popover.Body p={3}>
                        <Text color="text.reversed.primary" variant="callout">{description}</Text>
                    </Popover.Body>
                </Popover.Content>
            </Popover.Container>
        </Box>
    );
};

export default StatusBadge;
