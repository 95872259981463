import React from 'react';
import { Box, Icon, Stack, Text, BoxProps, useToast } from 'pws-design-system/design-system';

const getVariantStyles = (variant: string): any => {
    const styles: any = {};

    switch (variant) {
    case 'success':
        styles.bg = 'green.500';
        styles.color = 'text.light.primary';
        break;
    case 'error':
        styles.bg = 'red.500';
        styles.color = 'text.light.primary';
        break;
    default:
        break;
    }

    return styles;
};

interface ToastProps extends BoxProps {
    variant?: 'none' | 'success' | 'error' | 'info';
    title?: string;
    message: string;
    icon?: string;
}

const Toast = ({ variant = 'none', title, message, icon, ...rest }: ToastProps) => (
    <Stack
        spacing={2}
        m={3}
        p={3}
        bg="bg.base.primary"
        rounded="lg"
        boxShadow="lg"
        isInline
        {...getVariantStyles(variant)}
        {...rest}
    >
        {icon && <Icon name={icon} width="20px" height="20px" />}
        <Box textAlign="left">
            <Text variant="title3">{title}</Text>
            <Text variant="caption1">{message}</Text>
        </Box>
    </Stack>
);

export { useToast };

export default Toast;
